import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '../layout/index.vue'

const routes = [
  {
    path:'/excelToJson',
    component:()=>import('@/views/basic/ExcelToJson.vue')
  },
//   {
//     path:'/',
//     name:'home',
//     component:()=>import('@/views/home')
//   },
{
    path:'/login',
    name:'login',
    component:()=>import('@/views/login/index.vue')
  },
  {
    path:'/register',
    name:'register',
    component:()=>import('@/views/login/register.vue')
  },
  {
    path: '/',
    name: 'Layout',
    redirect:'/gb',
    component:Layout,
    children:[
      {
        path:'gb',
        redirect:'/gb/qtgd',
        name:'GB',
        component:()=>import('@/layout/components/GbPage.vue'),
        children:[
          {
            path:'qtgd',
            name:'qtgd',
            component:()=>import('@/views/gb/Qtgd.vue'),
          },
          {
            path:'ytgd',
            name:'ytgd',
            component:()=>import('@/views/gb/Ytgd.vue'),
          },
          {
            path:'ytcg',
            name:'ytcg',
            component:()=>import('@/views/gb/Ytcg.vue'),
          },
          {
            path:'ytgdlcjr',
            name:'ytgdlcjr',
            component:()=>import('@/views/gb/Ytgdlcjr.vue'),
          },
          {
            path:'ytgdlcdw',
            name:'ytgdlcdw',
            component:()=>import('@/views/gb/Ytgdlcdw.vue'),
          },
          {
            path:'szytgdqyMore',
            name:'szytgdqyMore',
            component:()=>import('@/views/gb/SzytgdqyMore.vue'),
          },
          {
            path:'szytgdqySpace',
            name:'szytgdqySpace',
            component:()=>import('@/views/gb/SzytgdqySpace.vue'),
          },
          {
            path:'zfll',
            name:'zfll',
            component:()=>import('@/views/gb/Zfll.vue'),
          },
          {
            path:'ftsl',
            name:'ftsl',
            component:()=>import('@/views/gb/Ftsl.vue'),
          },
          {
            path:'szzl',
            name:'szzl',
            component:()=>import('@/views/gb/Szzl.vue'),
          },
        ]
      },
      {
        path:'ccps',
        name:'CCPS',
        redirect:'/ccps/qtxl',
        component:()=>import('@/layout/components/CcpsPage.vue'),
        children:[
          {
            path:'qtxl',
            name:'qtxl',
            component:()=>import('@/views/ccps/Qtxl.vue'),
          },
          {
            path:'ytxl',
            name:'ytxl',
            component:()=>import('@/views/ccps/Ytxl.vue'),
          },
          {
            path:'sbdl',
            name:'sbdl',
            component:()=>import('@/views/ccps/Sbdl.vue'),
          },
          {
            path:'qtxf',
            name:'qtxf',
            component:()=>import('@/views/ccps/Qtxf.vue'),
          },
        ]
      }
    ]
  },
  {
      path: '/home',
      name: 'home-page',
      component:Layout ,
  }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
