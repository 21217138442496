import router from '../router'

import http from "./axios";

import { ElMessage } from 'element-plus'


import {useRoute} from 'vue-router'
export const checkToken=()=>{
  const token=sessionStorage.getItem('token')
  const username=sessionStorage.getItem('name')
  const route=useRoute()
  if((!token||!username)){
    route.path.includes('ccps')&&router.push('/')
  }else{
    http({
      url: "/checkToken",
      method: "post",
      data: {
          token,
          username
      },
  })
      .then((res) => {
          if(!res.data.available){
              sessionStorage.clear()
              ElMessage.error('当前登录已过期，请重新登录')
              router.push('/')
          }else{
            setTimeout(()=>{
              checkToken()
            },60000)
          }
      })
  }
}

