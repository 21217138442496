<template>
    <div class="main">
        <el-container style="height: 100%" direction="vertical">
            <el-header class="header" height="60px">
                <!-- <span class="title">泄漏源模型</span> -->
                <img src="../assets/stem-logo.png" alt="" style="height: 50px;margin: 5px 15px 0 0;float: left;">
                <div
                    class="menu-item"
                    @click="changeMenuItemStyle('gb')"
                    :class="{ active: activeIndex === 'gb' }"
                >
                    国 标
                </div>
                <div
                    class="menu-item"
                    @click="changeMenuItemStyle('ccps')"
                    :class="{ active: activeIndex === 'ccps' }"
                >
                    C C P S
                    <el-icon v-if="username==='点击登录'" class="bottom-icon"><Lock /></el-icon>
                </div>
                <div class="bar">
                    <span class="bar-button" @click="checkStatus(username)">{{
                        username
                    }}</span
                    >｜<span @click="toCsat" class="bar-button">返回主页</span
                    >｜<span class="bar-button" @click="openInfo"
                        >关于我们</span
                    >
                </div>
            </el-header>
            <el-main class="main-el">
                <div class="main-board">
                    <router-view></router-view>
                </div>
            </el-main>
            <el-footer style="height: 50px; padding: 5px">
                <img
                    style="height: 100%; cursor: pointer"
                    src="@/assets/img/app-logo.png"
                    @click="jump"
                />
                <img style="height: 100%" src="@/assets/img/app-code.png" />
            </el-footer>
            <AboutModal ref="aboutModalRef" />
        </el-container>
    </div>
</template>

<script setup>
import { InfoFilled,Lock } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { watch, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import AboutModal from "./components/AboutModal.vue";
const route = useRoute();
const router = useRouter();
let username = "";
const activeIndex = ref("gb");

const getUsername = () => {
    if (!sessionStorage.getItem("name")) {
        username = "点击登录";
    } else {
        username = sessionStorage.getItem("name");
    }
};
getUsername();

const toCsat = () => {
    window.location.href = "http://processsafetytool.com/";
};

const changeMenuItemStyle = (path) => {
    if (activeIndex.value === path) return;
    console.log(path =="ccps" , !sessionStorage.getItem('name'))
    if(path =="ccps" && !sessionStorage.getItem('name')){
        router.push('/login')
    } else{
        router.push(`/${path}`);
    }
    
};

const checkStatus = (name) => {
    if (name == "点击登录") {
        router.push("/login");
    } else {
        ElMessageBox.confirm(
            "是否注销账户？",
            "Warning",
            {
                confirmButtonText: "继续",
                cancelButtonText: "取消",
                type: "warning",
            }
        )
            .then(() => {
                sessionStorage.clear()
                username = "点击登录"
                
                ElMessage({
                    type: "success",
                    message: "注销成功",
                });
                if(route.path==='/gb/qtgd'){
                  router.go(0)

                }else{
                  router.push('/')
                }
            })
            .catch(() => {
                
            });
    }
};

watch(
    () => route.path,
    (val) => {
        if (val.includes("/gb/")) {
            activeIndex.value = "gb";
        }
        if (val.includes("/ccps/")) {
            activeIndex.value = "ccps";
        }
    },
    { immediate: true, deep: true }
);

const jump = () => {
    window.open("https://china-psc.com/");
};

const aboutModalRef = ref();

const openInfo = () => {
    aboutModalRef.value.visible = true;
};
</script>
<style scoped>
.bar-button {
    cursor: pointer;
    transition: all 0.3s;
}
.bar-button:hover {
    color: rgb(188, 188, 188);
}
.bar {
    float: right;
    font-size: 14px;
}
/* .info-btn {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

button:hover:after {
    left: 120%;
    transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
    -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
} */
.menu-item {
    font-size: 16px;
    color: white;
    float: left;
    height: 60px;
    /* width: 80px; */
    padding: 0 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.6s;
    font-weight: 300;
}
.menu-item:hover {
    background-color: rgba(255, 255, 255, 0.308);
}
.header {
    background-color: rgb(53, 64, 85);
    color: white;
    line-height: 60px;
}
.title {
    font-size: 24px;
    font-weight: 600;
    float: left;
    margin-right: 40px;
}
.main-el {
    background-color: rgb(230, 233, 241);
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
}
.main-board {
    height: 100%;
    border-radius: 15px;
    padding: 0 10px 0 0;
    overflow-y: hidden;
}
#app,
.main,
.el-container {
    padding: 0;
    margin: 0;
    height: 100%;
}

.active {
    height: 100%;
    font-weight: 600;
    background: rgb(230, 233, 241) !important;
    /* border-bottom: 10px solid white; */
    font-size: 20px;
    color: rgb(53, 64, 85);
}
.bottom-icon {
  
  bottom: -2px; /* 调整这个值以适应您的需求 */
}

</style>
