<template>
  <el-dialog v-model="visible" title="关于我们">
    <div style=" text-indent: 2em;">
      STEM（源项模型）由海归博士张彬教授带领的国际化研发团队开发，是化工行业内专业面向源项计算的软件。软件提供了丰富的物质属性数据，也提供了国家标准和国际公认的的源项计算模型。软件采用模型经过数据验证，可靠性高，行业需求迫切。 如需相关技术支持，可关注微信公众号或访问实验室网站，联系我们。
    </div>
    <img src="@/assets/img/info-code.png" style="width: 100%;"/>
  </el-dialog>
</template>
<script setup>
import {ref} from 'vue'

const visible=ref(false)

defineExpose({visible})
</script>